<template>
  <div class="jp-common-layout page">
    <div class="jp-common-layout-left">
      <div class="jp-common-el-tree-scrollbar el-scrollbar">
        <div class="el-scrollbar__wrap">
          <div class="el-scrollbar__view">
            <el-menu @select="select" style="border:0" :default-active="index">
              <el-menu-item-group>
                <el-menu-item index="1" style="text-align: center">我的档案</el-menu-item>
                <el-menu-item index="2" style="text-align: center">我的收藏</el-menu-item>
                <el-menu-item index="3" style="text-align: center">我的分享</el-menu-item>
                <el-menu-item index="4" style="text-align: center">馆内分享</el-menu-item>
                <el-menu-item index="5" style="text-align: center">我的借阅</el-menu-item>
                <el-menu-item index="6" style="text-align: center"
                  v-if="hasPermission('myFile:myFile:approval')">借阅审批</el-menu-item>
              </el-menu-item-group>
            </el-menu>
          </div>
        </div>
      </div>
    </div>
    <!--    我的档案    -->
    <index v-if="index == 1" />
    <myCollection v-if="index == 2" />
    <myShare v-if="index == 3" />
    <pavilionShare v-if="index == 4" />
    <MyBorrow v-if="index == 5" />
    <BorrowApp v-if="index == 6" />
  </div>
</template>
<script>
import index from './index'
import myCollection from './myCollection/List'
import myShare from './myShare'
import pavilionShare from './pavilionShare'
import MyBorrow from './borrow/MyBorrow'
import BorrowApp from './borrow/BorrowApp'
export default {
  data() {
    return {
      index: '1',
    }
  },
  components: {
    index,
    myCollection,
    myShare,
    pavilionShare,
    MyBorrow,
    BorrowApp
  },
  mounted() {
    this.select('1')
  },
  methods: {
    //切换
    select(val) {
      this.index = val
    },
  }
}
</script>

<style>
.el-header {
  color: #333;
  line-height: 60px;
  height: 30px;
}

.el-aside {
  color: #333;
  border-right: 1px solid rgb(238, 238, 238);
}
</style>
